
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9fb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #009aad;
  border-radius: 19px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0079ff;
}

*{
  font-family: sans-serif ;
}

button{
  cursor: pointer;
}