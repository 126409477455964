@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

/* * {
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}

body {
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d2e0f7;
  padding: 1rem 0rem 2rem;
} */

.card-section {
  /* box-shadow: 0 4px 25px #a7c5f1; */
  color: #4473be;
}
.card-section h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
}
.card-section h4 {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 1.25rem;
}
.card-section h5 {
  margin-top: 0.75rem;
  font-size: 0.9rem;
  opacity: 0.6;
  margin-bottom: 0;
}
.card-section p {
  margin-top: 1.5rem;
  font-size: 0.8rem;
  line-height: 1.4;
  font-weight: 400;
}
.card-section button {
  margin-top: 1rem;
  border: none;
  outline: none;
  background-color: #7da3e0;
  color: #fff;
  padding: 0.8rem 1rem;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  font-weight: 700;
}

.containersub {
  height: 100vh;
  margin: 0 auto;
  background-attachment: fixed;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
}
.h3div{

  background: linear-gradient(to left, #009AAD, #008EB5);
  margin-top: -24px;
  height: 71px;
  width: 100%;
}
.containersub .container-top {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
    top: -21px;
}
.containersub .container-top h2 {
  font-size: 1.75rem;
  color: #ffffff;
}
.containersub .container-top input[type="checkbox"] {
  position: relative;
  width: 70px;
  height: 35px;
  appearance: none;
  background-color: #9cbff8;
  border-radius: 20px;
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  transition: 0.5s ease all;
  outline: none;
  cursor: pointer;
}
.containersub .container-top input[type="checkbox"]::before {
  content: '';
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-color: #fff;
  top: 0;
  left: 0;
  transition: 0.5s ease all;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: scale(0.9);
}
.containersub .container-top input:checked[type="checkbox"] {
  background-color: #4473be;
}
.containersub .container-top input:checked[type="checkbox"]::before {
  left: 35px;
}
.containersub .container-content {
  height:90% ; 
  margin-top: 1.5rem;
  display: flex;
   align-items: center;
  justify-content: center;
}
.containersub .container-content2 {
  height:74% ; 
  margin-top: 1.5rem;
  
   align-items: center;
  justify-content: center;
}
.containersub .container-content .starter {
  background-color: #fff;
  margin-right: 2rem;
  border-radius: 4px;
  /* padding: 2rem; */
  /* box-shadow: 0 4px 25px #a7c5f1; */
  color: #4473be;
}
.containersub .container-content .professional {
  background-color: #fff;
  border-radius: 4px;
  /* padding: 2rem; */
  /* box-shadow: 0 4px 25px #a7c5f1; */
  color: #4473be;
}
.containersub .container-content .professional button {
  margin-top: 1rem;
  border: none;
  outline: none;
  background-color: #fff;
  border: 2px solid #7da3e0;
  color: #7da3e0;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .containersub .container-top h2 {
    font-size: 1.5rem;
  }
  .containersub .container-content {
    margin-top: 1.5rem;
    flex-direction: column;
  }
  .containersub .container-content .starter {
    margin-right: 0;
  }
  .containersub .container-content .professional {
    margin-top: 2rem;
  }
}


.starter{
  
    width: 542px;
}


.starter li::before {
  content: '• ';
  color: #7da3e0;
}


.professional{
  
  width: 767px;

}

.infosub{
  /* padding-top: 30px; */
  /* width: 566px; */
  margin: auto;
  /* margin-top: -12px; */
}
.pro_price{
  font-size: 23px;
  margin-top: -11px;
  color:  black;
}

.h3div h4{
  position: relative;
  top: 19px;
  font-size: 24px;
  font-weight: 500;
  color: white;
  margin-top: -7px;
}
.h3div h3{
  position: relative;
  top: 19px;
  font-size: 24px;
  font-weight: 500;
  color: white;
  margin-top: -7px;
}
.h4sub{
  position: relative;
  top: 19px;
  font-size: 24px;
  font-weight: 500;
  color: white;
}


ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

li {
  margin: 0.5rem 0;
  font-weight: 700;
  color: black;
}

.professional li::before {
  content: '• ';
  color: #7da3e0;
}


.imgcircle{
  background-color: red;
  display: inline-block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
}

.btnpre{
  margin-top: 41px;
    background: #ffe500;
    height: 53px;
    border-radius: 20px;
    border-color: #ffe50000;
    font-size: 20px;
    font-family: system-ui;
    font-weight: 500;
}

.pro_h2{
  font-size: 23px;
    margin-top: 12px;
    color: white;
}

.container-top span {

  position: absolute;
    top: 28px;
    right: 26px;
    font-weight: 700;
    color: white;
}

ul{
  margin-left: 23px;
}




