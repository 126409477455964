.btnpreprofile{
    
  padding: 5px;
  background: #ffe02b;
  /* height: 34px; */
  border-radius: 20px;
  border-color: #ffe50000;
  font-size: 14px;
  font-family: system-ui;
  font-weight: 500;
  }
  .avat{
    height: 148px !important;
    width: 149px !important;
  }
  .allcontainer {
    width: 100%;
    height: 20%;
    /* margin-left: 147px; */
    margin-top: 36px;
     flex-direction: column;
  
  }





  .homecontainer {
        background: #f0f0f0;
    /* height: 100vh; */
    /* display: flex; */
    /* flex-direction: column;
    align-items: center;
    justify-content: center; */
    /* height: 51vw; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* width: 100vw;
    height: 100vh; */
  }
  

  .profilename {
    text-align: center;
    /*position: relative;
    left: 1vw !important;*/
  }
  .profilename h2{
      font-size: 29px;
      margin-bottom: 6px;
      min-width: 20vh;
  }
  /* .profile{
    margin-top: -71vh;
  } */

  .css-1vm0r4s-MuiPaper-root{
    border-radius: 67px !important;
    /* margin: 55px!important; */
  }

  .css-1vm0r4s-MuiPaper-root{
    width: 38vw !important;
    height: 55px !important;
    border-color: #0098C7 !important;
    border: 1px solid #0098C7 !important;
  }

  .droplist{
    justify-content: space-evenly;
    /* margin-left: 6%; */
    /* width: max-content; */
    display: flex;
    flex-direction: row;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 114rem;
    column-gap: 15vw;
    row-gap: 9rem;
    margin-top: 10px;
  }

  @media (max-width: 1200px){
    .droplist {
      column-gap: 2vw;
      margin-left: -70px
    }
  }
  /* .list1{
    width: 54%;
  } */

/* 
  .list3{
    width: 54%;
    margin-left: 43%;
  } */

.profile{
  text-align: center;
  display: flex;
  /*margin-block-end: 10vw;*/
}  

  .search_menu_selection {
    /* background-color: #009AAD!important; */
    height: 53px!important;
    font-size: 18px!important;
    /* width: 104%!important; */
    border-radius: 47px!important;
    font-weight: 600!important;
    font-family: sans-serif!important;
    text-transform: inherit!important;
  }

  .iconProfile{
    width: 131px;
    height: 127px;
    border-radius: 62px;
    border: 2px solid #AAA;
    object-fit: cover;
    margin-left: 0px;
    margin-right: 4px;
  }

  .btnpreprofile{
    cursor: pointer;
  }

  /* .css-1x0waax-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root{
    width: 17% !important;
    top: 286px!important;
    left: 904px!important;
  } */


  .css-1x0waax-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root .MuiMenuItem-root{
    width: 300px;
  }

  /* .css-1x0waax-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root{
    top: 282px!important;
    left: 1036px!important;
   
  } */

  .actions{
    /* height: 46px; */
    padding: 3px;
    background-color: aliceblue;
    margin-bottom: 13px
  }
  .actuins{
    background-color: aliceblue;
    padding-block: 1px;
  }
  .actuins span{
    font-size: 30px;
    font-weight: 400;
    margin-left: 15px;
    cursor: pointer;
    color: #0098C7;
  }

  .actions span{
    font-size: 20px;
    font-weight: 400;
    margin-left: 15px;
    cursor: pointer;
    color: #0098C7;
  }
  .divli span{

    position: relative;
    font-size: 43px;
    top: 4px;

    cursor: pointer;
    color: #0098C7;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list{
    background-color:aliceblue ;
  }



  .ulflag {
    list-style: none!important;
    padding: 0;
  }

  .ulflag li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    list-style: none!important;
    list-style-type: none; 
    content: none !important;
  }



  .flag-icon {
    margin-right: 10px;
  }

  .startArticle{
    position: relative;
    left: 8px;
    width: 22px;
    height: 19px;
    position: relative;
    /* margin-right: 0px; */
    top: 5px;
  }

  
  .actions  a {
    text-decoration: none;
    color: inherit;
    font-size: 22px;
    margin-left: 9px;
  }
  .divli  a {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    margin-left: 9px;
  }
  .actuins  a {

    font-weight: 600;
    text-decoration: none;
    color: #0098C7;
    font-size: 17px;
    margin-left: 1px;
  }

  /* .sidprof{
    height: 34%;
    
  } */



  .file-input{
    align-items: center;
    display: flex;
    margin-left: -59px;
    position: relative;
    left: 102px;
  }


  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #0098C7;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  
  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }
  

  .css-1bq3xq-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root{
    min-width: 292px !important;
  }

  .icon{
    margin-left: 49px;
  }

  .search{
    margin-left: 80px;
  }

  .bgcontainer{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
  }

  #stack_thing_in_header {
      width: 27vw;
      justify-content: space-evenly;
  }