.msg-head{
    display: flex;
}
.msg-div{
    display: flex;
    justify-content: center;
}

.messageinp{
    position: relative;
    height: 220px;
    width: 59vh;
    /* height: 73px; */
    font-size: 18px;
    border-radius: 10px;
    background-color: #04a2ce;
    border: none;
    outline: none;
    transform: translate(5px,-3px);
    color: white;
    padding: 10px;
    font-weight: bold;
    top: 8px;
}

.messageinp::placeholder {
    color: #ffff; /* Your desired placeholder color */
  }
.Msgarea::placeholder {
    color: #ffff; /* Your desired placeholder color */
  }

  .inputtow{
    border-radius: 17px;
    width: 50%;
    background-color: #05abc2;
    padding: 15px;
    margin-right: 15px;
  }

  .Msgarea{
    width: 100%;
    height: 128px;
    font-size: 18px;
    border-radius: 10px;
    background-color: #008EB5;
    border: none;
    outline: none;
    transform: translate(5px,-3px);
    color: white;
    padding: 10px;
    font-weight: bold;
  }
  
.icons{
    display: flex;
     justify-content: center;
}
  
.icons img{
    height: 26px;
    margin-right: 14px;
    width: 25px;
}
  
.icons span{
    color: white;
    margin-right: 14px;
    font-size: 31px;
    font-weight: 600;
    margin-top: -1px;
  
}

.inputOne{

    height: fit-content;
    width: 50%;
    margin-right: 63px;
    background-color: #04a2ce;
   
    border-radius: 10px;
    padding-bottom: 19px;
    margin-left: 32px;
    margin-right: 15px;
}

.serachams{
    margin-right: 70px;
    margin-left: auto;
}
.msg-head h1{
  font-size: 29px;
    color: #04a2ce;
    margin-left: 60px;
    margin-top: 10px;
}


.postcontainer{
    margin-left: 22px;
    border-radius: 26px;
    background-color: #eeeeee;
    padding: 20px;
    width: 50%;
    margin-top: 27px;
}

.profimg{
    display: flex;
    align-items: center;
}

.profimg img{
    
    width: 91px;
    height: 90px;
    border-radius: 62px;
    border: 2px solid #AAA;
    object-fit: cover;
    margin-left: 5px;
    margin-right: 10px;
}

.profname h2{
    margin: 0;
    font-size: 21px;
}
.heart{

    width: 36px!important ;
    height: 36px !important ;
    border: none !important ;

}
.verificato{
    width: 28px!important;
    height: 27px !important;
    border: none !important ;

}

.divivcons{
    margin-bottom: auto;
    margin-left: auto;
}

.divivcons span{
    font-size: 35px;
    font-weight: bold;
    font-size: 30px;
    position: relative;
    top: -4px
}

.number{
    position: relative!important ;

    top: -10px!important ;

    margin-left: 6px!important ;

    font-size: 30px!important ;

    font-weight: 700!important ;

}

.articlemsg img{
    border-radius: 22px;
    width: 100%;
}

.articlemsg span{
    color: #008EB5;
}
.articlemsg h2{
    font-weight: 700;
}
.articlemsg p{
    font-weight: 700;
    line-height: 20px;
}

.inpcom::placeholder {
    color: #AAA; 
    font-size: 20px;
    font-weight: 600;
  }
.inpcom {
    position: relative;
    top: -7px;
  }

  .commenaireContainer{
    margin-left: 22px;
    border-radius: 26px;
    background-color: #ffffff;
 
    width: 45%;
    margin-top: 27px;

  }
  .commenaireContainer input {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 81%;
    margin-top: 20px;
    border-radius: 67px;
    border: none;
    /* margin-left: 21px; */
    height: 60px;
    /* margin-right: 9px; */
}
  .inppd img{
    margin-top: 15px;
    width: 55px;
    height: 56px;
    border-radius: 30px;
    border: 2px solid #AAA;
    object-fit: cover;
    margin-left: 5px;
    margin-right: 10px;
  }

  .inppd{
    display: flex;
    background-color: #f5f5f5;
    border-radius: 45px
  }

  .proficom{
    
    width: 71%;
    display: flex;
    align-items: center;
    margin-left: 8%;
    margin-top: 29px;

  }

  .proficom img{
    position: relative;
    margin-top: 15px;
    width: 55px;
    height: 56px;
    border-radius: 30px;
    border: 2px solid #AAA;
    object-fit: cover;
    margin-left: 5px;
    margin-right: 10px;
    top: -12px;
  }

  .spanp{
    position: relative !important;
    top: -21px !important;
    font-size: 15px !important;
    font-weight: 700 !important;

    position: inherit !important;
    margin-top: -20px!important;

  }

  .jaimespan{

    font-size: 15px !important;
    font-weight: 700 !important;
    color: #02b3e3 !important;
   
   
  }

  .jaimdiv{
    text-align: center;
    display: flex;
    /* justify-content: center; */
    margin-left: 119px;
  }

  .jaimeicon{
    width: 20px!important;
    height: 18px !important;
    border: none !important;
    margin-right: 13px;
  }

  .jaimdiv p{
    margin: 0;
  }