
form.gsc-search-box.gsc-search-box-tools{
    width: 35vw !important;
}

td.gsc-input{
    margin: 0px;
    padding: 0px;
}

div#gsc-iw-id1 {
    width: 35vw;
    height: 55px;
    border: 2px solid #008eb5;
    border-radius: 28px;

}

table#gs_id50 {
    margin-top: 7px;
    margin-left: 10px;
}

tr:has(td#gs_tti50) {
    width: 100px;
}

td#gs_tti50 {

}

td.gsib_b {
    border: none;
    display: none;
}

input#gsc-i-id1{ /* search-bar */
    margin-top: 4.5px !important;
    background: none !important;
    background-image: none;
}


button.gsc-search-button-v2 {
    z-index: 1;
    margin-left: -6.5vw;
    background: rgba(149, 34, 34, 0);
    border: none;
}

button.gsc-search-button-v2 svg{
    fill: black;
    transform: scale(1.6);
}


#___gcse_0 /*(.gcse-searchbox)*/ {
    /*margin-right: 10vw;*/
    margin-top: 25px;
    /*background-color: red;*/
}
