.friendcontainer {
  margin-left: 36px;
  min-width: 650px;
}
.friend {
  display: flex;
  min-width: 309px;
  max-width: 330px;
}

.friend img {
  width: 80px;
  height: 80px;
  border-radius: 79px;
  border: 2px solid #aaa;
  object-fit: cover;
  margin-left: -3px;
  margin-right: 9px;
}

.infodivfriend{
  margin-top: -6px;
}
.friend p {
  text-align: left;
  font-size: 17px;
  font-weight: 700;
  margin-top: 26px;
  /* margin-left: -150px; */
  font-family: sans-serif;
}
.infofriendspan {
  position: relative !important;
  top: -21px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #009aad!important;
  position: inherit !important;
  margin-top: -20px!important;
}

.friend span {
  cursor: pointer;
  color: red;
  margin-left: auto;
  margin-right: 7px;
  font-size: 28px;
  font-weight: 600;
  align-items: center;
  display: flex;
  margin-top: -2px;
}

.friendcontainer h1 {
  color: #009aad;
  font-size: 22px;
  margin-left: 9px;
  position: relative;
  top: -20px;
}

.allFriends {
  display: grid;
  grid-template-columns: 0fr 1.5fr;
  column-gap: 33px;
  row-gap: 1rem;
  justify-content: space-around;
}

.allFriendsseg {
  /* margin-top: 20px; */
  /* height: 37%; */
  /* margin-left: 13%; */
  display: grid;
  grid-template-columns: 0fr 1.5fr;
  /* max-width: 78%; */
  column-gap: 13px;
  row-gap: 1rem;
}

.titlefriend {
  display: flex;
  margin-left: 23px;
}
.titlefriend img {
  width: 44px;
  height: 32px;
  position: relative;
  top: -11px;
}

.friendseg img {
  width: 119px;
  height: 109px;
  border-radius: 50%;
  overflow: hidden;
  background-color: blue;
}

.friendseg {
  text-align: center;
  padding: 22px;
    width: 221px;
    background-color: #e4e2e2;
    border-radius: 29px;
}
.friendseg button {
  margin-top: 10px;
  width: 109px;
  border: none;
  /* padding: 13px; */
  color: white;
  background-color: #009aad;
  height: 34px;
  font-size: 19px;
  /* width: 119%; */
  border-radius: 47px;
  font-weight: 600;
  font-family: sans-serif;
  text-transform: inherit;
}
.friendseg p {
  height: 2px;
  font-size: 17px;
  font-weight: 700;
  margin-top: 13px;
  /* margin-left: -150px; */
  font-family: sans-serif;
}

.friendseg span {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #009aad !important;
}


.h2seg {    
  color: #009aad;
  font-size: 27px;
  margin-left: 9px;
}


  #imgCancel{
    border: none !important;
    width: 20px!important;
    height: 20px!important;
    cursor: pointer;
    align-self: center;
    margin-bottom: 10px;
    /* To align to the right */
    display: block;
    margin-left: auto;
    margin-right: 22px;
}