.Conditions{
    margin-left: 44px;
}
.Conditions h1{
    text-align: center;
    color: #188cff;
}
.Conditions h3{
    color: #188cff;
}
.Conditions p{
 font-weight: 600;}