.listprofmg{
    display: flex;
    align-items: center;
    background: rgb(2,0,36);
    background:#cae1e1;
    height: 122px;
    width: 89%;
    border-radius: 21px;}

.listprofmgi {
    width: 91px;
    height: 90px;
    border-radius: 62px;
    border: 2px solid #AAA;
    object-fit: cover;
    margin-left: 5px;
    margin-right: 10px;
}

.imgcancelmsg{
    border: none !important;
    width: 25px!important;
    height: 26px!important;
    cursor: pointer;
    position: relative!important;
    margin-left: 55px!important;
}

.Listmsg{
    justify-content: center;
    display: flex;
    margin-top: 18px;
    cursor: pointer;
}

.msgtxt{
    align-items: center;
    margin-left: 73px;
    background-color: white;
    height: 93px;
    border-radius: 21px;
    width: 63%;
    display: flex;
}

.infofriendspanmsg{    position: relative !important;
    top: -14px !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #0098C7 !important;
   
}

.txtt{
    margin-left: 21px
}


.vu{
    display: flex;
    margin-left: auto;
}
.vu h1{
    color: #C0C0C0;
    font-size: 26px;
    font-family: system-ui;
    font-weight: 500;
    margin: 0;
}

.vu img{
    width: 45px;
    height: 38px;
    border-radius: 62px;
    /* border: 2px solid #AAA; */
    object-fit: cover;
    margin-left: 22px;
    margin-right: 10px;
}

.vu p{
    color: #C0C0C0;
    font-size: 26px;
    font-family: system-ui;
    font-weight: 500;
    margin: 0;
}

.txtt p {
    
    font-size: 23px;
    font-weight: 600;
    color: #C0C0C0;
    margin-bottom: 14px;

}

.infofriendspanmsg span{
    position: relative !important;
    top: -14px !important;
    font-size: 15px !important;
    /* font-weight: 700 !important; */
    color: #0098C7 !important;
}