.profnameprof h2{
    margin: 0;
    font-size: 29px;
}
.profnameprof h1{
    margin: 0;
    font-size: 39px;
}

.profnameprof img {
    width: 167px;
    height: 160px;
    border-radius: 108px;
    border: 2px solid #AAA;
    object-fit: cover;
    margin-left: 5px;
    margin-right: 10px;
}

.btnpremm{
    padding: 0 15px;
    background: #ffe500;
    height: 32px;
    border-radius: 20px;
    border-color: #ffe50000;
    font-size: 14px;
    font-family: system-ui;
    font-weight: 500;
    margin-top: 9px;
}
.publication{
   cursor: pointer;
    padding: 22px;
    margin-bottom: 14px;

    background-color: #eeeaea;
    border-radius: 29px;
}
.publication h4{
 
    background-color: #009aad;
    position: relative;
    height: 0;
    margin: 0;
    top: 8px;
    left: 13px;
    color: #009aad;
    font-size: 14px;
    cursor: pointer;
}

.publications h1{    color: #009aad;
    font-size: 25px;
    
}

.publications {
    width: 29%;
}
.publications2 h1{    color: #009aad;
    font-size: 25px;
    
}

.publications2 {
    margin-left: 57px;
    margin-bottom: 14px;   
     width: 36%;
}


.item-photoProf{
    width: 100%;
    height: 178px;
    border-radius: 30px;
}

.publication h3{
    margin-top: 7px;
    margin-bottom: -2px;
}

.publication p{
    margin-bottom: 15px;
    font-size: 13px;
    font-weight: 600
}

.allPub{

display: flex;
}












@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@200;400;600;700&display=swap");


:root {
	--primary: #ae7efe;
	--outdark: #573f7f;
	--white: #009aad;
}


.timeline {
	position: relative;
	/* max-width: 960px; */
	margin: 0 auto;
}
.timeline::after {
	content: "";
	position: absolute;
	width: 3px;
	background-color: var(--white);
	top: 0;
	bottom: 0;
	left: 50%;
}
.container {
	padding: -2px;
    position: relative;
    background-color: inherit;
    width: 50%;
    
    margin-left: 3px;
    margin-right: -69px;
    
}
.container::after {
    border-radius: 4px;
    content: "";
    position: absolute;
    width: 12px;
    height: 9px;
    right: -8px;
    background-color: #040404;
    border: solid 4px #ffffff;
    top: 16px;
    /* border-radius: 50%; */
    z-index: 1;

}
.left {
	left: 0;
}
.right {
	left: 50%;
}
/* .left::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	right: 30px;
	border: medium solid var(--white);
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent var(--white);
} */
.right::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	left: 30px;
	border: medium solid var(--white);
	border-width: 10px 10px 10px 0;
	border-color: transparent var(--white) transparent transparent;
}
.right::after {
	left: -12px;
}
.content {
    margin-left: -77px;
    position: relative;
    top: 4px;
    display: flex;
}

@media screen and (max-width: 600px) {
	.timeline::after {
		left: 31px;
	}
	.container {
		width: 100%;
		padding-left: 70px;
		padding-right: 25px;
	}
	.container::before {
		left: 60px;
		border: medium solid var(--white);
		border-width: 10px 10px 10px 0;
		border-color: transparent var(--white) transparent transparent;
	}
	.left::after,
	.right::after {
		left: 15px;
	}
	.right {
		left: 0%;
	}
}

.content h2{
    font-size: 14px;
}


.ddd{
    height: 8%;
    content: "";
    position: absolute;
    width: 6px;
    background-color: #009aad;
    top: 31px;
    bottom: 0;
    left: 50%;
    /* position: relative; */
    left: -95px;
    border-radius: 20px;}

.profileContainer{
    padding-left: 47px;
    padding-right: 47px;
}

.apropos{
    cursor: pointer;
    height: 19px;
    background-color: aliceblue;
    margin-bottom: 9px;
    font-size: 13px;
    display: flex;
}

.apropos span{
    color: black;
    position: relative;
    top: -2px;
    font-size: 23px;
    font-weight: 300;
    margin-right: 7px;
}

.profimg{
    cursor: pointer;
}

.imgprofil img{
    height: 66vh;
    border-radius: 25px;
    margin-right: 27px;
}
.Listinfo div{
    justify-content: center;
    text-align: center;
    display: flex;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
    /* padding: 1px 2px; */
    align-items: center;
    margin-bottom: 13px;
}
.Listinfo h3{
    padding: 0;
    margin: 0;
   
}

.imgInfo-Container{
    display: flex;
}
.profpuls{

    margin-bottom: 2px;
    display: flex;
    cursor: pointer;
    /* height: 19px; */
    background-color: white;
    font-size: 13px;
}
.profpuls span{
    margin-right: 7px;
    font-size: 28px;
}
.profpuls li{
    font-size: 15px;
    position: relative;
    top: -6px;}

.ulprof{
    list-style: none!important;
    padding: 0;
}

.listinfo-details{
    margin-left: 30px;
    background-color: #ffffff;
    border-radius: 20px;
    max-width: 33%;
}

.ProfilInfo{
    height: 95vh;
    padding-left: 28px;
}

.supprofi{
 
        font-size: 11px!important;
        position: relative!important;
        top: -15px!important;
        color: #00C2FE!important;
        font-weight: 700!important;
}

.biospan{
    margin-right: 7px!important;
    font-size: 14px!important;
    font-weight: 600!important;
}

.licpmp{
    font-size: 16px;
  
}


.Obtenu{
    font-size: 13px!important;
    color: #00C2FE!important;}
.Obtenu span{
    font-size: 13px!important;
    color: black!important;
}



.voirdiv{
    border-radius: 14px;
    background-color: #02b3e3 !important;
    width: 131px;
    margin-top: -22px;
    position: relative;
    top: 23px;
    margin-left: 11px;
}
.voirdiv span{
    font-size: 12px!important;
    margin-left: 3px!important;
    position: relative !important;
    top: -2px !important;
    /* font-size: 15px !important; */
    font-weight: 700 !important;
    color: #000000 !important;
    /* position: inherit !important; */
    /* margin-top: -20px!important; */
}

.h2details{
    width: 0;
    position: relative;
    left: 30px;
    white-space: nowrap;
}



.blur{
    border-radius: 30px;

    background-color: #eaebeea3;
    pointer-events: none;
    position: fixed;
    top: 172px;
    /* left: 416px; */
    width: 21%;
    height: 730px;
}

.blur2{
    width: 26%;
    border-radius: 30px;
    background-color: #eaebeea3;
    pointer-events: none;
    position: fixed;
    top: 172px;
    /* left: 768px; */
    /* width: 369px; */
    height: 730px;
}


.list1{
    display: flex;
}
.suivrebtn{
    background-color: transparent;
    color: rgb(2, 179, 227);
    font-size: 17px;
    font-weight: 700;
    position: relative;
    top: 2px;
    /* margin-left: -19px; */
    border: none;
    border-radius: 15px;
}
.suivrebtn:hover{
    background-color:  rgb(2, 179, 227);
    color: white;
   

}